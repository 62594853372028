import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { getRecommendationResults } from '../../../../services/Recommendations';
import RecommendationResultsList from '../../../RecommendationResultsList/RecommendationResultsList';

const RecommendationResults = () => {
  const [recommendationResults, setRecommendationResults] = useState([]);

  useEffect(() => {
    getRecommendationResults().then((data) => {setRecommendationResults(data ? data : []);});
  }, []);

  return (
    <div className='recommendation-results__container'>
      <Typography variant='h3' gutterBottom>Recommendation Results</Typography>
      <Box sx={{ mt: 4 }}>
        {recommendationResults.length > 0 ? (
          <RecommendationResultsList recommendationResults={recommendationResults} />
        ) : (
          <Typography>No recommendations found.</Typography>
        )}
      </Box>
    </div>
  );
};

export default RecommendationResults;
