import React from 'react';
import { List } from '@mui/material';
import RecommendationResultItem from '../RecommendationResultItem/RecommendationResultItem';

const RecommendationResultsList = ({ recommendationResults }) => {
    //each value in recommendationResults array has unique id. I've tested it.
    return recommendationResults && (
        <List sx={{ width: '100%' }}>
            {recommendationResults.map((result) => {
                return (
                    <RecommendationResultItem key={result.id} result={result} />
                );
            })}
        </List>
    );
};

export default RecommendationResultsList;
