import React, { createContext, useState, useEffect, useContext } from "react";
import useNavigationListener from "./hooks/useNavigationListener";
import { getUser } from "./services/Auth";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null); // Stores the logged-in user's data
  const [loading, setLoading] = useState(true); // Tracks loading state
  const location = useLocation(); // Hook to get the current location
  const excludedRoutes = ["/login"]; // Routes where we don't fetch the user
  // Redirect to login when 'navigateToLogin' event is triggered
  useNavigationListener("navigateToLogin", "/login");

  const fetchUser = async () => {
    try {
      const userData = await getUser();
      setUser(userData);
      if(!userData?.spotifyUserProperties){
        navigate("/link-spotify");
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error fetching user:", error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch user initially only if the current route is not excluded
    if (!excludedRoutes.includes(location.pathname)) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <AuthContext.Provider value={{ user, loading, refreshUser: fetchUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
