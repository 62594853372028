import React, { useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import LoginPage from "./components/Pages/LoginPage/LoginPage";
import Error from "./components/Pages/Error/Error";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutline from "@mui/icons-material/PersonOutline";

import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";

import "./App.css";
import Profile from "./components/Pages/Profile/Profile";
import RecommendationsRouter from "./components/Pages/Recomendations/RecommendationsRouter";
import Recommend from "./components/Pages/Recomendations/Recommend/Recommend";
import RecommendationResults from "./components/Pages/Recomendations/RecommendationResults/RecommendationResults";
import RecommendationResult from "./components/Pages/Recomendations/RecommendationResult/RecommendationResult";
import KeddyIcon from "./components/KeddyIcon/KeddyIcon";
import { AuthProvider } from "./AuthProvider";
import LinkSpotify from "./components/Pages/LinkSpotify/LinkSpotify";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabValue, setTabValue] = React.useState(false); // Set the initial value
  const [accountAnchorEl, setaccountAnchorEl] = React.useState(null);
  const accountPopupOpen = Boolean(accountAnchorEl);

  // Memoize the pageMappings object to avoid re-creating it on every render
  const pageMappings = useMemo(
    () => ({
      recommend: "/recommendations/recommend",
      recommendationResults: "/recommendations/results",
    }),
    []
  );

  const menuItemSelected = (value) => {
    switch (value) {
      case "settings":
        break;
      case "profile":
        navigate("/profile");
        break;
      case "logout":
        navigate("/login");
        break;
      default:
        break;
    }
    setaccountAnchorEl(null);
  };

  useEffect(() => {
    const matchedKey = Object.keys(pageMappings).find((key) =>
      location.pathname.startsWith(pageMappings[key])
    );

    setTabValue(matchedKey || null); // Set tab value to the matched key or null if no match is found
  }, [location.pathname, pageMappings]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    // Navigate to the selected tab's route based on pageMappings
    if (pageMappings[newValue]) {
      navigate(pageMappings[newValue]);
    } else {
      setTabValue(null);
      navigate("/error");
    }
  };

  const handleTabClick = (value) => {
    if (value === "recommendationResults") {
      navigate(pageMappings[value]);
    }
  };

  return (
    <div className="App">
      {tabValue && (
        <AppBar
          position="fixed"
          sx={{
            height: { xs: "48px", sm: "48px" }, // 56px on small screens (xs), 64px on larger screens (sm and up)
            display: "flex",
            flexDirection: "row",
            paddingLeft: "5px",
            paddingRight: "15px",
          }}
        >
          <KeddyIcon></KeddyIcon>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "white", // Set the color of the line
              marginLeft: "8px", // Adjust spacing between KeddyIcon and Divider
            }}
          />
          <Tabs
            sx={{ flexGrow: 1 }}
            value={tabValue}
            textColor="inherit"
            indicatorColor="secondary"
            onChange={handleChange}
          >
            <Tab label="Recommendations" value="recommend" />
            <Tab
              label="Recommendation Results"
              value="recommendationResults"
              onClick={() => handleTabClick("recommendationResults")}
            />
          </Tabs>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor: "white", // Set the color of the line
              marginRight: "8px", // Adjust spacing between KeddyIcon and Divider
            }}
          />
          <IconButton
            id="account-button"
            aria-controls={accountPopupOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={accountPopupOpen ? "true" : undefined}
            onClick={(event) => {
              setaccountAnchorEl(event.currentTarget);
            }}
            style={{ padding: "0px" }}
          >
            <PersonIcon
              sx={{ color: "white", width: "35px", height: "35px" }}
            />
          </IconButton>
          <Menu
            id="account-menu"
            anchorEl={accountAnchorEl}
            open={accountPopupOpen}
            onClose={(value) => {
              setaccountAnchorEl(null);
            }}
            MenuListProps={{
              "aria-labelledby": "account-button",
            }}
          >
            <MenuItem onClick={() => menuItemSelected("profile")}>
              <ListItemIcon>
                <PersonOutline fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={() => menuItemSelected("settings")}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem onClick={() => menuItemSelected("logout")}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </AppBar>
      )}
      <Box sx={{ paddingTop: { xs: "48px", sm: "48px" } }}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/link-spotify" element={<LinkSpotify />} />
          <Route path="/recommendations" element={<RecommendationsRouter />}>
            <Route path="" element={<Navigate to="recommend" />} />
            <Route path="recommend" element={<Recommend />} />
            <Route path="results" element={<RecommendationResults />} />
            <Route
              path="results/:resultId"
              element={<RecommendationResult />}
            />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<Navigate to="/recommendations" />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<Navigate to="/error?code=not_found" />} />
        </Routes>
      </Box>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  );
}

export default AppWithRouter;
