import React, { useEffect, useState } from "react";
import "./Profile.css";
import KeddyHeader from "../../KeddyHeader/KeddyHeader";
import { Typography } from "@mui/material";
import {
  getSpotifyRegisterURI
} from "../../../services/Auth";
import SpotifyLinkButton from "../../SpotifyLinkButton/SpotifyLinkButton";
import { useNavigate } from "react-router-dom";

function Profile() {
  const [spotifyURI, setSpotifyURI] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    getSpotifyRegisterURI()
      .then((data) => {
        setSpotifyURI(data);
      })
      .catch((error) => {
        console.error("Failed to fetch Spotify URI:", error);
        navigate("/error"); // Optional, to navigate to an error page
      });
  }, [navigate]);

  const onSpotifyLinkClicked = () => {
    if (spotifyURI) window.location.href = spotifyURI;
  };

  return (
    spotifyURI != null && (
      <div className="profile__container">
        <div className="profile__content">
          <div style={{ marginBottom: 35 }}>
            <KeddyHeader />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Relink spotify:</Typography>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 35,
            }}
          >
            <SpotifyLinkButton
              onClick={onSpotifyLinkClicked}
            ></SpotifyLinkButton>
          </div>
        </div>
      </div>
    )
  );
}

export default Profile;
