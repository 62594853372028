import axiosInstance from "./axiosInstance";


const loginOrRegisterUser = async (phoneNumber, verificationCode) => {
  try {
    const response = await axiosInstance.post(`/api/auth/login`, {
      phoneNumber,
      verificationCode,
    });
    if (response?.status !== 200) {
      throw new Error("An error occurred.");
    } else {
      localStorage.setItem("keddyToken", response.data.jwt);
    }
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.Err || "An error occurred");
    } else {
      throw new Error("An error occurred");
    }
  }
};

const getUser = async () => {
  try {
    const response = await axiosInstance.get(`/api/user/profile`);
    if (response?.status !== 200) {
      throw new Error("Response status not 200");
    } else {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

const sendVerification = async (phoneNumber) => {
  try {
    const response = await axiosInstance.post(`/api/auth/validate-phone`, null, {
      params: {
        phone: phoneNumber,
      },
    });
    if (response.status !== 200) throw new Error("An error occured");
    return true;
  } catch (error) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.Err || "An error occurred");
    } else {
      throw new Error("An error occurred");
    }
  }
};

const getSpotifyUserProperties = async () => {
  const response = await axiosInstance.get(`/api/spotify-credentials/user-properties`);
  return response?.data?.exists;
};

const linkSpotifyUserProperties = async (authCode, stateCode) => {
  try {
    const response = await axiosInstance.get(`/api/spotify-credentials/callback`, {
      params: {
        code: authCode,
        state: stateCode,
      }
    });

    return response;
  } catch (error) {
    return error.response;
  }
};


const getSpotifyRegisterURI = async () => {
  try {
    const response = await axiosInstance.get(`/api/spotify-credentials/register`);
    return response?.data?.spotifyURI;
  }
  catch (e) {
    console.error("Error:", e);
    return null;
  }
};

const clearLoginToken = async () => {
  localStorage.removeItem('keddyToken');
};

export {
  loginOrRegisterUser,
  sendVerification,
  clearLoginToken,
  getSpotifyRegisterURI,
  getSpotifyUserProperties,
  linkSpotifyUserProperties,
  getUser
};