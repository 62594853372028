import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Paper } from '@mui/material';

const RecommendationTrackItem = ({ trackDto }) => {
    // Ensure the album object and images array exist
    const album = trackDto.trackData.album || {};
    const albumImages = Array.isArray(album.images) ? album.images : [];

    // Get the smallest image available from the album
    const albumImageUrl = albumImages.length > 0 ? albumImages[albumImages.length - 1].url : '';

    return (
        <Paper elevation={3} sx={(theme) => ({ marginBottom: '5px', borderRadius: '8px', overflow: 'hidden', width: '100%', height: '85px', paddingLeft: '5px', backgroundColor: theme.palette.primary.main })}>
            <ListItem
                sx={(theme) => ({
                    backgroundColor: theme.palette.primary.main, // Use primary background color (black)
                    color: theme.palette.primary.contrastText, // Use primary text color (white)
                    padding: '2px',
                    borderRadius: '8px',
                    height: '100%', // Constant height for all list items
                    display: 'flex', // Ensure items inside are aligned properly
                    alignItems: 'center', // Align content vertically
                    width: '100%', // Make the item take 100% of the width
                })}
            >
                {/* Album image */}
                {albumImageUrl && (
                    <ListItemAvatar>
                        <Avatar
                            src={albumImageUrl}
                            alt={`${album.name} album cover`}
                            variant="square"
                            sx={{ width: 60, height: 60, borderRadius: '8px' }} // Make the album image a bit roundy
                        />
                    </ListItemAvatar>
                )}

                {/* Track details */}
                <ListItemText
                    sx={{ paddingLeft: '1rem', overflow: 'hidden', whiteSpace: 'nowrap' }} // Ensure parent also manages overflow
                    primary={
                        <Typography variant="h6"
                            sx={(theme) => ({
                                color: theme.palette.common.white,
                                fontSize: '1rem', // Reduce font size to ensure it fits
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '100%',
                            })}
                        >
                            {trackDto.trackData.name} {/* Track name text color: white */}
                        </Typography>
                    }
                    secondary={
                        <Typography variant="body2" sx={(theme) => ({
                            color: theme.palette.background.default, fontSize: '0.8rem', fontStyle: 'italic', whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                        })}>
                            {trackDto.trackData.artists[0]?.name || 'Unknown Artist'} {/* Artist name color: beige (background default) */}
                        </Typography>
                    }
                />
            </ListItem>
        </Paper>
    );
};

export default RecommendationTrackItem;
