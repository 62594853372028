class RecommendationResult {
    constructor(id, userId, title, userPrompt, timeCreated, tracks, likedTracks, valence, arousal, vibeId) {
      this.id = id;
      this.userId = userId;
      this.title = title;
      this.userPrompt = userPrompt;
      this.timeCreated = timeCreated;
      this.tracks = tracks;
      this.likedTracks = likedTracks;
      this.valence = valence;
      this.arousal = arousal;
      this.vibeId = vibeId;
    }
  
    // Static method to create a RecommendationResult object from JSON data
    static fromJSON(data) {
      return new RecommendationResult(
        data.id,
        data.userId,
        data.title || null,
        data.userPrompt || null,
        new Date(data.timeCreated), // Convert timeCreated to a JS Date object
        data.tracks ? new Set(data.tracks) : new Set(), // Convert to a Set
        data.likedTracks ? new Set(data.likedTracks) : new Set(), // Convert to a Set
        data.valence,
        data.arousal,
        data.vibeId || null
      );
    }    
  }
  
  export default RecommendationResult;
  