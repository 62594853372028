import axiosInstance from "./axiosInstance";
import Emotion from "../models/Emotion";
import EmotionTrackMapping from "../models/EmotionTrackMapping";
import RecommendationDto from "../models/RecommendationDto";
import RecommendationResult from "../models/RecommendationResult";

const getEmotions = async () => {
  try {
    const response = await axiosInstance.get(`/api/recommendations/emotions`);
    const emotions = response.data.map(Emotion.fromJson);
    return emotions;
  } catch (error) {
    console.error("Error fetching emotions:", error);
    return null;
  }
};

const getEmotionTrackMappings = async (withAllEmotions = true) => {
  try {
    const response = await axiosInstance.get(
      `/api/recommendations/emotion-track-mappings`,
      {
        params: {
          withAllEmotions: withAllEmotions,
        },
      }
    );
    const emotionTrackMappingList = response.data.map(
      EmotionTrackMapping.fromJSON
    );
    return emotionTrackMappingList;
  } catch (error) {
    console.error("Error fetching emotionTrackMappingList:", error);
    return null;
  }
};

const getRecommendations = async (textPrompt, coordinate, vibeId) => {
    try {
      const requestBody = {
        prompt: textPrompt || null, // Include prompt if provided
        coordinate: coordinate || null, // Include coordinate if provided
        vibeId: vibeId || null, // Include vibeId if provided
      };
  
      const response = await axiosInstance.post(
        `/api/recommendations/recommend`, 
        requestBody
      );
  
      return response.data;
    } catch (error) {
      console.error("Error fetching recommendations:", error);
      return null;
    }
  };
  

const getRecommendationDtoByResultId = async (resultId) => {
  try {
    const response = await axiosInstance.get(
      `/api/recommendations/recommendation/${resultId}`
    );
    const recommendations = RecommendationDto.fromJSON(response.data);
    return recommendations;
  } catch (error) {
    console.error("Error fetching recommendation by result id:", error);
    return null;
  }
};

const getRecommendationResults = async () => {
  try {
    const response = await axiosInstance.get(`/api/recommendations/results`);
    const results = response.data.map(RecommendationResult.fromJSON);
    return results;
  } catch (error) {
    console.error("Error fetching recommendation results:", error);
    return null;
  }
};

export {
  getEmotions,
  getEmotionTrackMappings,
  getRecommendations,
  getRecommendationDtoByResultId,
  getRecommendationResults,
};
