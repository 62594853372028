import React, { useEffect, useState} from 'react';
import './RecommendationResult.css';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom'; // Import useParams to get the id from the URL
import RecommendationTracksList from '../../../RecommendationTracksList/RecommendationTracksList';
import { getRecommendationDtoByResultId } from '../../../../services/Recommendations';

function RecommendationResult() {
    const { resultId } = useParams(); // Extract resultId from the URL
    const [recommendations, setRecommendations] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const fetchRecommendations = async () => {
            setLoading(true);
            const recommendations = await getRecommendationDtoByResultId(resultId);
            setRecommendations(recommendations);
            setLoading(false);
        };

        fetchRecommendations();
    }, [resultId]);

    return (
        <div className='recommendation-result__container'>
            <div className='recommendation-result__content'>
                <Typography variant='h3' gutterBottom>Recommendation Results Page</Typography>
            </div>
            {/* Display recommendation if available */}
            <Box sx={{ mt: 4 }}>
                {loading ? (
                    <Typography>Loading...</Typography> // Show loading indicator
                ) : recommendations ? (
                    <RecommendationTracksList recommendations={recommendations} />
                ) : (
                    <Typography>No recommendations found for this ID</Typography>
                )}
            </Box>
        </div>
    );
}

export default RecommendationResult;