import React from 'react';
import { List, Typography, Box } from '@mui/material';
import RecommendationTrackItem from '../RecommendationTrackItem/RecommendationTrackItem';

const RecommendationTracksList = ({ recommendations }) => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>Recommendations</Typography>
            <List>
                {recommendations.tracks.map((trackDto, index) => (
                    <RecommendationTrackItem key={index} trackDto={trackDto} />
                ))}
            </List>
        </Box>
    );
};

export default RecommendationTracksList;
